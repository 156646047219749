<template>
    <v-dialog 
        width="30%" 
        v-model="show"
        :fullscreen="isMobileSize()"
        persistent
        transition="dialog-bottom-transition"
    > 
        <v-card>
            <v-card-title class="header py-4">
                {{ title }}
            </v-card-title>
            <v-divider/>
            <v-card-text class="pa-4">
                <slot></slot>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn 
                    @click="confirm()"
                    class="success"
                >
                    Confirm
                </v-btn>
                <v-btn 
                    @click="cancel()"
                    class="error"
                >
                    Cancel
                </v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: Boolean,
        title: {
            type: String,
            required: true
        }
    },
    computed: {
        show() {
            return this.value
        },
    },
    methods: {
        confirm() {
            this.$emit('confirm')
            this.$emit('input', false)
        },
        cancel() {
            this.$emit('input', false)
        }
    }
}
</script>